<template>
  <main class="claim-botb">
    <h1 class="claim-botb__title">
      BOTB OG Bull Unvaulting
    </h1>
    <div v-if="!isConnected">
      <button
        v-if="!isWalletConnecting"
        class="claim-botb__button"
        @click="connectWallet"
      >
        {{ isWalletConnecting ? "Connecting" : "Connect Wallet" }}
      </button>
      <p v-if="isWalletConnecting">
        Connecting...
      </p>
    </div>
    <div v-if="!isCollectionLoading && isConnected">
      <BOTBNav
        :selected-tab="selectedTab"
        :handle-change-tab="handleChangeTab"
      />

      <button
        v-if="collectionLength > 0 && haveClaimableTokens"
        class="claim-botb__button"
        @click="claimAll"
      >
        {{ isClaiming ? "Ubvaulting..." : "Unvault All" }}
      </button>
      <div class="claim-botb__cards">
        <TokenCard
          v-for="token in selectedCollection || []"
          :id="token.id"
          :key="token.id"
          :image="token.image"
          :claimable="token.isClaimable"
        />
      </div>

      <div
        v-if="selectedCollection?.length === 0"
        class="claim-botb__no-tokens"
      >
        <p>No {{ selectedTabName }} found in your wallet.</p>
      </div>
    </div>
    <div v-if="isCollectionLoading">
      <img
        class="loader"
        src="@/assets/img/spinner-clear.svg"
        width="86"
      >
    </div>
  </main>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import BOTBNav from "@/components/BOTB/BOTBNav.vue";
import TokenCard from "@/components/BOTB/TokenCard.vue";
import { useWeb3Store } from "@/store/web3";
import { connectWallet } from "@/web3";
import { useBapApi } from "@/api/useBapApi";
import { useToast } from "@/composables/useToast/useToast";
import { claimBOTB } from "@/functions/botb";
import getNFTOwner from "@/functions/getNFTOwner";

const web3Store = useWeb3Store();

const selectedTab = ref("claimable");
const selectedTabName = ref("Claimable");
const collectionLength = ref(0);

const isConnected = computed(() => {
  return web3Store.isConnected;
});
const isWalletConnecting = ref(false);
const isCollectionLoading = ref(false);
const isClaiming = ref(false);

const wallet = computed(() => {
  return web3Store.walletAddress;
});

const collection = ref({
  ogTokens: [],
  evoTokens: [],
  bearTokens: [],
});

watch(
  () => web3Store.isConnected,
  (isConnected) => {
    if (isConnected) {
      getTokens();
    } else {
      collection.value.ogTokens = [];
      collection.value.evoTokens = [];
      collection.value.bearTokens = [];
      connect();
    }
  }
);

const canBeClaimed = async (tokenId) => {
  const ogContractAddress = "0x5221b7fe10506a4341d402851fd44919d7b11255";

  try {
    const owner = await getNFTOwner({
      tokenId: tokenId,
      contractAddress: "0x3a8778A58993bA4B941f85684D74750043A4bB5f",
    });

    // check if owner is the OG contract
    if (owner.toLowerCase() !== ogContractAddress.toLowerCase()) return false;
    return true;
  } catch (error) {
    console.error(error);
  }
};

const getTokens = async () => {
  if (!isConnected.value) return;
  try {
    isCollectionLoading.value = true;
    const { success, result } = await useBapApi({ throw: true }).exec({
      url: `/mint/botb`,
      params: {
        wallet: wallet.value,
      },
    });

    if (!success) {
      useToast().error({ title: "Error", message: "Something went wrong" });
      return;
    }
    collection.value.ogTokens = [...result.ogTokens];
    collection.value.evoTokens = [...result.evoTokens].map((token) => ({
      ...token,
      isClaimable: ![...result.ogTokens].some((t) => t.id === token.id),
    }));
    collection.value.bearTokens = [...result.bearTokens];
    collectionLength.value =
      result.ogTokens.length +
      result.evoTokens.length +
      result.bearTokens.length;

    for (let i = 0; i < collection.value.evoTokens.length; i++) {
      const token = collection.value.evoTokens[i];
      if (token.isClaimable) {
        const canClaim = await canBeClaimed(token.id);
        if (canClaim) {
          collection.value.evoTokens[i].isClaimable = true;
        } else {
          collection.value.evoTokens[i].isClaimable = false;
        }
      }
    }
  } catch (error) {
    useToast().error({ title: "Error", message: "Something went wrong" });
  } finally {
    isCollectionLoading.value = false;
  }
};

const selectedCollection = computed(() => {
  if (selectedTab.value === "claimable") {
    return collection.value.evoTokens.filter((t) => t.isClaimable);
  }
  return collection.value[selectedTab.value];
});

const connect = async () => {
  isWalletConnecting.value = true;
  await connectWallet();
  isWalletConnecting.value = false;
};

const handleChangeTab = (tab) => {
  selectedTab.value = tab;
  switch (tab) {
    case "bearTokens":
      selectedTabName.value = "BOTB Bear";
      break;
    case "evoTokens":
      selectedTabName.value = "BOTB EVO Bull";
      break;
    case "ogTokens":
      selectedTabName.value = "BOTB OG Bull";
      break;
    case "claimable":
      selectedTabName.value = "Claimable Tokens";
      break;
    default:
      selectedTabName.value = "";
  }
};

const claimAll = async () => {
  try {
    isClaiming.value = true;
    await claimBOTB([
      ...collection.value.evoTokens
        .filter((t) => t.isClaimable)
        .map((t) => t.id),
    ]);

    useToast().success({
      title: "Success",
      message: "Unvaulted successfully",
    });
  } catch (error) {
    useToast().error({ title: error.message.split("{")[0] });
  } finally {
    isClaiming.value = false;
  }
};

const haveClaimableTokens = computed(() => {
  return collection.value.evoTokens.filter((t) => t.isClaimable).length > 0;
});
</script>
